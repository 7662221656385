import { useEffect } from 'react';
import Prompt from '../components/shared/Prompt';

export default function TermsAndConditions() {
  useEffect(() => {
    document.title = 'CarAnalysis | Home';
  }, []);

  return (
    <div>
      <div className='bg-car-background bg-cover bg-center h-48' />
      <div className='flex flex-col mt-12 mb-32 w-5/6 lg:w-4/5 2xl:w-3/4 mx-auto space-y-6'>
        <h2 className='text-sub1 md:text-h2 text-p-blue-dark'>
          <span className='font-bold'>Car Analysis&nbsp;</span>
          <span>Terms and Conditions</span>
        </h2>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>Terms of Use</h3>
          <p>
            Published on 10 March 2023. These terms of use replace and supersede
            all prior versions, from the date of publication.
          </p>
          <p>
            These Terms of Use (“Terms”) are an agreement between you and Car
            Valuations Pty Ltd (ACN 658 712 906) (“Car Valuations”, “we”, “us”,
            or “our”). Please read these Terms carefully as they govern your use
            of and access to our website (“Site”) and information about the
            valuations of vehicles made available on our Site (“Services”).
          </p>
          <p>
            By accessing our Site and Services, you agree to be bound by these
            Terms. If you do not agree to these Terms, then you must not access
            or use our Site and Services.
          </p>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            Purpose of our Site and Services
          </h3>
          <p>
            Our Site and Services are intended as a source of general
            information about the valuation of vehicles and must only be used
            for personal and non-commercial use. Valuations are based on
            information gathered from a range of third-party sources. Any
            valuation of a vehicle produced by our Site and/or Services may be
            out of date at any given time, and we are under no obligation to
            update such material.
          </p>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>No warranty</h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              You understand and agree that your access to and use of our Site
              and Services is at your own initiative and risk. Subject to your
              consumer rights and except as required by law, we do not in any
              way represent, warrant or guarantee:
            </p>
            <ol className='list-decimal mx-5 lg:mx-8'>
              <li>
                that the content of our Site and Services are accurate, complete
                or up to date;
              </li>
              <li>
                continuous, uninterrupted, or secure access to our Site and
                Services;
              </li>
              <li>
                that our Site and Services will be fit for any particular
                purpose;
              </li>
              <li>
                that our Site and Services are free of malware or other harmful
                components; or
              </li>
              <li>
                the accuracy of any vehicle valuation generated by our Site or
                Services (or any third party on behalf of us).
              </li>
            </ol>
            <p>
              You must not rely on the vehicle valuations produced by our Site
              and Services without making your own independent assessment of the
              valuation of a vehicle. You should consult other third-party
              sources of information in determining the valuation of a vehicle.
              We will not be liable to you or anyone else who relies on the
              information available on our Site or the information (including
              but not limited to vehicle valuations) generated by the Services.
              Please see the “limitation of liability” section below for a
              detailed explanation of the limits of our liability to you.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>Prohibitions</h3>
          <div className='text-p1 lg:text-p2'>
            <p>You must not use our Site or Services:</p>
            <ol className='list-decimal mx-5 lg:mx-8'>
              <li>
                for any unlawful purpose or for any purpose prohibited by these
                Terms;
              </li>
              <li>
                to infringe any intellectual property or proprietary rights of
                any third party;
              </li>
              <li>
                to solicit others to perform or participate in any unlawful
                acts;
              </li>
              <li>
                to harass, abuse, insult, harm, defame, slander, disparage,
                intimidate, or discriminate;
              </li>
              <li>to submit false or misleading information;</li>
              <li>to upload viruses or other malicious code;</li>
              <li>to collect or track the personal information of others;</li>
              <li>to spam, phish, pharm, pretext or crawl;</li>
              <li>for any obscene or immoral purpose; or</li>
              <li>to interfere with or circumvent their security features.</li>
            </ol>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            Your consumer rights
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              These Terms are subject to consumer protection laws and
              regulations including the Australian Consumer Law as set out in
              Schedule 2 of the Competition and Consumer Act 2010 (Cth)).
              Nothing in these Terms is intended to exclude, restrict or modify
              rights which you may have under any law which may not be excluded,
              restricted or modified by agreement. You can find out more about
              your consumer rights from consumer organisations and bodies such
              as the Australian Competition and Consumer Commission and
              Australian State/ Territory fair trading authorities.
            </p>
            <p>
              If these Terms are governed by the Australian Consumer Law, you
              may have certain consumer rights under that legislation. Subject
              to your consumer rights, and in addition to the “limitation of
              liability” section below, we are not liable
            </p>
            <ol className='list-decimal mx-5 lg:mx-8'>
              <li>
                for any loss that was not reasonably foreseeable to us; and
              </li>
              <li>
                to the extent that your loss was contributed to by you or any
                other matter outside our reasonable control.
              </li>
            </ol>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            Limitation of liability and indemnity
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              For the purpose of this section, references to “we”, “us” or “our”
              mean Car Valuations Pty Ltd (ACN 658 712 906), our related bodies
              corporate, directors, partners, representatives, officers, agents,
              contractors and employees, whether individually or collectively
            </p>
            <p>
              To the maximum extent permitted by law, in no event will we be
              liable to you or anyone else, whether under contract, tort,
              statute or in equity, for any kind of loss (including any real or
              anticipated loss of profit, loss of benefit, loss of revenue, loss
              of business, loss of goodwill, loss of opportunity, loss of
              savings, loss of reputation, loss of use and/or loss or corruption
              of data) incurred or suffered by you or anyone else arising out of
              or in connection with these Terms or your access to and use of our
              Site or Services.
            </p>
            <p>
              Our aggregate liability to you under or in connection to these
              Terms (whether in tort (including negligence) for breach of
              contract or breach of statute or otherwise) is limited to the
              resupply of the Services or a refund of any fees paid for the
              Services by you.
            </p>
            <p>
              You agree to indemnify and hold us harmless from and against all
              claims, losses, costs, damages and expenses (including reasonable
              legal fees) suffered or incurred by us arising out of or related
              to your breach of any of these Terms or your use of or the
              provision of our Site and Services.
            </p>
            <p>
              This clause will survive the termination or expiry of these Terms.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>Privacy policy</h3>
          <p className='text-p1 lg:text-p2'>
            Please see our{' '}
            <a href='/privacy-policy' className='underline'>
              privacy policy
            </a>{' '}
            for information about how we collect, handle, use and disclose
            personal information. In accessing and using our Site and Services
            you agree to be bound by our privacy policy as amended from time to
            time.
          </p>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>Waiver</h3>
          <p className='text-p1 lg:text-p2'>
            The failure of us to exercise or enforce any right or provision of
            these Terms does not operate as a waiver of such right or provision.
          </p>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>Severability</h3>
          <p className='text-p1 lg:text-p2'>
            If any provision of these Terms is determined to be unlawful, void
            or unenforceable in any jurisdiction, such provision will
            nonetheless be enforceable to the fullest extent permitted by
            applicable law, and the unenforceable portion will be deemed to be
            severed from these Terms. Such determination will not affect the
            validity and enforceability of any other remaining provisions of
            these Terms.
          </p>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            Changes to our Terms
          </h3>
          <p className='text-p1 lg:text-p2'>
            You can review the most current version of these Terms at any time
            on our Site. We reserve the right, at our sole discretion, to
            update, change or replace any part of our Terms by posting updates
            and changes to our Site. It is your responsibility to check our Site
            periodically for changes. Your continued use of or access to our
            Services following the posting of any changes to our Terms
            constitutes acceptance of those changes.
          </p>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>Governing law</h3>
          <p className='text-p1 lg:text-p2'>
            These Terms are governed by and construed under the laws of
            Victoria.Each party irrevocably and unconditionally submits to the
            non-exclusive jurisdiction of the courts of Victoria.
          </p>
        </div>
      </div>
      <Prompt />
    </div>
  );
}
