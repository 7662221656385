import { Link, useLocation } from 'react-router-dom';

export default function Footer() {
  const { pathname } = useLocation();

  return (
    <div>
      {pathname !== '/payment' && pathname !== '/success' && (
        <div className='bg-primary-blue-dark'>
          <div className='flex flex-col items-center py-16 space-y-4'>
            <div className='bg-white py-4 px-6 rounded-md'>
              <img
                src={require('../../icons/logo.png')}
                className='h-6 sm:h-10'
                alt='car-analysis-logo'
              />
            </div>
          </div>
        </div>
      )}
      <footer className='bg-p-blue-dark flex flex-wrap justify-center space-x-3 md:space-x-8 text-white text-[10px] py-8'>
        <Link className='hover:underline' to='/terms-and-conditions'>
          Terms and Conditions
        </Link>
        <div className='hidden xs:block w-px bg-white' />
        <Link className='hover:underline' to='/privacy-policy'>
          Privacy Policy
        </Link>
        <div className='hidden xs:block w-px bg-white' />
        <p>Car Valuations copyright 2024</p>
      </footer>
    </div>
  );
}
