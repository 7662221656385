import ReactDOM from 'react-dom';

export default function GTMNoscript() {
  return ReactDOM.createPortal(
    <noscript>
      <iframe
        src='https://www.googletagmanager.com/ns.html?id=GTM-N48CWV88'
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>,
    document.body
  );
}
