import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { stripeServer } from '../../api/stripe-router';
import { PaymentComponentProps } from './CardDetails';

export default function ApplePay({
  setPaymentError,
  setPaymentSuccess,
  setPaymentProcessing,
  getReport,
  ppsrDisabled,
}: PaymentComponentProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'AU',
      currency: 'aud',
      total: {
        label: 'CarAnalysis Report total',
        amount: ppsrDisabled ? 1200 : 1600,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr as any);
      }
    });

    pr.on('paymentmethod', async e => {
      setPaymentProcessing(true);

      const {
        data: { clientSecret },
      } = await stripeServer('/caranalysis/payment-intent');

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: e.paymentMethod.id,
        },
        {
          handleActions: false,
        }
      );

      if (error) {
        e.complete('fail');
        setPaymentError(error.message || 'Could not process payment');
        return;
      }

      e.complete('success');

      if (paymentIntent && paymentIntent.status === 'requires_action') {
        stripe.confirmCardPayment(clientSecret);
      }

      if (paymentIntent && paymentIntent.status === 'succeeded') {
        await getReport();
        setPaymentSuccess(true);
      }
    });
  }, [stripe, elements]);

  return (
    <div className='my-4'>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
    </div>
  );
}
