import { omit } from 'lodash';
import { FormEvent, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  VehicleDetails,
  useFacetVehicleSearchLazyQuery,
  useGetValuationLazyQuery,
  useLookupVehicleLazyQuery,
} from '../../graphql/generated';
import FacetsForm from './FacetsForm';
import OdometerSlider from './OdometerSlider';
import RegoForm from './RegoForm';
import SearchError from './SearchError';
import VehicleSearchResults from './VehicleSearchResults';

export type FacetOptions = 'make' | 'model' | 'year' | 'badge';

export interface FacetSearchParams {
  facet: FacetOptions;
  make: string;
  model: string;
  year: string;
  badge: string;
}

export default function VehicleSearchForm() {
  const [regoOrVin, setRegoOrVin] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [odometer, setOdometer] = useState<string>('');
  const [searchError, setSearchError] = useState<string | null>(null);

  const [displayManualSearch, setDisplayManualSearch] =
    useState<boolean>(false);
  const [facetSearchFields, setFacetSearchFields] = useState<FacetSearchParams>(
    {
      facet: 'make',
      make: '',
      model: '',
      year: '',
      badge: '',
    }
  );

  const [searchResults, setSearchResults] = useState<VehicleDetails[]>([]);
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState<number>(0);
  const [vehicleData, setVehicleData] = useState<any>(null);

  const redirect = useNavigate();

  const [lookupVehicle, { loading: vehicleLookupLoading }] =
    useLookupVehicleLazyQuery();

  const [facetVehicleSearch, { loading: facetVehicleSearchLoading }] =
    useFacetVehicleSearchLazyQuery();

  const [getValuation, { loading: valuationLoading }] =
    useGetValuationLazyQuery();

  const handleRegoOrVinLookup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchError(null);
    setSearchResults([]);

    const { data, error } = await lookupVehicle({
      variables: {
        rego_or_vin: regoOrVin,
        state,
      },
    });

    if (error) {
      setSearchError('lookup-error');
      return;
    }

    if (data?.lookup_vehicle) {
      setSearchResults(data.lookup_vehicle);
      setVehicleData(data.lookup_vehicle[selectedVehicleIndex]);
      return;
    }

    setSearchError('lookup-error');
  };

  const handleGetMyReport = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearchError(null);

    if (!vehicleData?.vehicle_id) {
      return;
    }

    const { vehicle_id, vehicle_title, vin } =
      searchResults[selectedVehicleIndex];

    const isVin = regoOrVin.length === 17;

    const regoOrVinVehiclePayload = {
      vehicle_id,
      rego: isVin ? undefined : regoOrVin,
      vin: isVin ? regoOrVin : vin,
      state,
      odometer,
    };

    const { data: valuationData, error } = await getValuation({
      variables: {
        input: regoOrVinVehiclePayload,
      },
    });

    if (error) {
      setSearchError('valuation-error');
      return;
    }

    if (vehicleData && valuationData?.get_valuation) {
      redirect('/payment', {
        state: omit(
          {
            ...regoOrVinVehiclePayload,
            ...valuationData.get_valuation,
            vehicle_title,
          },
          '__typename'
        ),
      });
    }
  };

  return (
    <div>
      <RegoForm
        regoOrVin={regoOrVin}
        setRegoOrVin={setRegoOrVin}
        setRegoOrVinLookupError={setSearchError}
        state={state}
        setState={setState}
        vehicleLoading={vehicleLookupLoading}
        handleSubmit={handleRegoOrVinLookup}
      />
      {displayManualSearch && (
        <div className='flex flex-col space-y-4'>
          <div className='bg-white h-px mt-4' />
          <p className='text-white text-p2 font-semibold mx-auto'>
            Manual Search
          </p>
          <FacetsForm
            facetSearchFields={facetSearchFields}
            setFacetSearchFields={setFacetSearchFields}
            setSearchResults={setSearchResults}
            setSearchError={setSearchError}
          />
        </div>
      )}
      {searchResults.length > 0 && (
        <>
          <div className='my-3'>
            <OdometerSlider
              value={Number(odometer)}
              handleChange={(kms: number) => setOdometer(String(kms))}
            />
          </div>
          <VehicleSearchResults
            setOdometer={setOdometer}
            searchResults={searchResults}
            valuationLoading={valuationLoading}
            handleGetMyReport={handleGetMyReport}
            selectedVehicleIndex={selectedVehicleIndex}
            setSelectedVehicleIndex={setSelectedVehicleIndex}
            vehicleLookupLoading={vehicleLookupLoading}
            facetVehicleSearchLoading={facetVehicleSearchLoading}
          />
        </>
      )}
      {searchError && (
        <SearchError
          error={searchError}
          setSearchError={setSearchError}
          setDisplayManualSearch={setDisplayManualSearch}
        />
      )}
    </div>
  );
}
